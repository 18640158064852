import { db } from '@/config/firebase';

async function retrieve(filterBy) {
    let stencilsObj = {};
    let query = db.collection('stencils');

    // TODO: Add filtering [dateFrom,dateTo,assetCode]

    query = query.orderBy('dateCreated', 'desc');

    const querySnapshot = await query.get();
    querySnapshot.forEach(doc => {
        let stencilObj = doc.data();
        stencilObj['id'] = doc.id;
        stencilsObj[doc.id] = stencilObj;
    });

    return stencilsObj;
}

async function create(stencil) {
    let query = db.collection('stencils');
    return query.add(stencil);
}

export default { retrieve, create }